import React, { useState, useEffect } from "react";
import fireworks from "react-fireworks";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import AMEX from "../../images/amex-card.png";

export default function Start() {
  useEffect(() => {
    fireworks.init("celebrate", { z_index: 0 });
    fireworks.start();
  }, []);
  return (
    <Layout>
      <SEO title="CARD" />
      <div className="row pad-30" id="celebrate">
        <div className="col-xs-12 text-align-center">
          <img src={AMEX} style={{ width: 225, zIndex: 500 }} />
        </div>
        <div
          className="col-xs-12 is-pink text-align-center"
          style={{ zIndex: 500 }}
        >
          <h4>
            Good choice.{" "}
            <a
              href="http://americanexpress.com"
              className="btn-link is-blue"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              Apply Now
            </a>
          </h4>
        </div>
      </div>
    </Layout>
  );
}
